import { useState, useEffect } from "react";
import {
  ParentCont,
  LeftPanel,
  RightPanel,
  RightPanelAuxCont,
  CloseBtn,
  DescriptionBox,
  RightPanelAuxCon2,
  DescriptionTitle,
  DescriptionText,
  DescriptionBtn,
  FormCont,
  TitleCont,
  Back,
  FormTitle,
  FormSubTitle,
  FormElement,
  Selectors,
  ActionButton,
  MiddlePanel,
  Indicator,
} from "./Styles";
import { Icon, Form } from "shared/components";
import { useHistory } from "react-router-dom";
import api from "shared/utils/api";
import {
  getStoreLocalStorage,
  omniGetter,
  omniRemover,
} from "shared/utils/authToken";
import toast from "shared/utils/toast";
import { similarity } from "shared/utils/classes/stringCompare";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import PageIndicator from "shared/components/PageIndicator";
import consoleLog from "shared/utils/consoleLog";
import { GatewayPages } from "../../PageList";
import {
  setBusinessLists,
  setCurrentBusiness,
} from "store/actions/businessAction";
import { setKycBoardingRec } from "store/actions/boardingAction";
import checkExistingKycBoarding from "shared/utils/checkExistingKycBoarding";

function AddAccount() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const kycBoardingRec = useSelector(
    (store: RootStateOrAny) => store.kycBoardingRec
  );
  const [loadingBanks, setLoadingBanks] = useState(false);
  const [banks, setBanks] = useState([]);
  const [bankCode, setBankCode] = useState(kycBoardingRec?.bankCode ?? "");
  const [bankAccId, setBankAccId] = useState(kycBoardingRec?.bankAccId ?? "");
  const [loadingAcc, setLoadingAcc] = useState(false);
  const [accName, setAccName] = useState(kycBoardingRec?.accName ?? "");
  const [bankName, setBankName] = useState(kycBoardingRec?.bankName ?? "");
  const [accNum, setAccNum] = useState(kycBoardingRec?.accNum ?? "");
  const [accNameFetch, setAccNameFetch] = useState(false);
  const [nameMatch, setnameMatch] = useState<any>("");
  const currentbusiness = useSelector(
    (store: RootStateOrAny) => store.business
  );
  const kycStart = useSelector((store: RootStateOrAny) => store.kycStart);

  const initialValues = {
    accountNumber: accNum,
    bankName: bankName,
    bankAccountName: accName,
    bankAccId,
  };

  const history = useHistory();

  const getBankList = async () => {
    setLoadingBanks(true);
    try {
      const res = await api.get("/checkout/bank");
      const { payload } = res;
      const banks = payload.filter((x: any) => !x.code || x.code == "");
      consoleLog("Banks without bank code: ", banks);
      consoleLog("bank list", payload);
      const bankList: any = Object.values(payload).map((role: any) => ({
        value: role.id,
        label: role.name,
        code: role.institutionCode,
      }));
      setBanks(
        bankList.sort((a: any, b: any) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        )
      );
      setLoadingBanks(false);
    } catch (error) {
      // @ts-ignore
      //toast.error(error);
      // consoleLog("bank ", error);
      setLoadingBanks(false);
    }
  };

  const getDetails = async () => {
    // consoleLog({accNum, bankCode});
    setLoadingAcc(true);
    setAccName("");
    // setBankName("");
    // setBankCode("");
    // setBankAccId("");
    // consoleLog({
    //   accNum,
    //   bankCode,
    // });
    try {
      const { status, message, payload }: any = await api.post(
        "/checkout/bank/verify-account",
        {
          account_number: accNum,
          institution_code: bankCode,
        }
      );

      if (status && payload.accountName) {
        const businessObject: any = getStoreLocalStorage("businessObject");
        // let business_name = JSON.parse(businessObject).rcBusinessName;
        let business_name = omniGetter("businessName");
        let bank_name = payload.accountName;
        const stringCompare = similarity(business_name, bank_name);
        setnameMatch(stringCompare);
        setAccName(bank_name);
        if (stringCompare < 0.8) {
          toast.error(
            "Account name does not match with the name of the business."
          );
        }
      } else {
        setAccName("");
        toast.error(message);
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error);
      consoleLog(error);
    } finally {
      setAccNameFetch(true);
      setLoadingAcc(false);
    }
  };

  const onSubmit = async (values: any, form: any) => {
    setIsLoading(true);

    try {
      let findRecord =
        currentbusiness?.BusinessKyc instanceof Array &&
        currentbusiness?.BusinessKyc.find(
          (el: any) => el?.type.toLowerCase() === "gateway"
        );

      let res: any;
      if (findRecord) {
        // alert("old");

        res = await api.patch(
          `/business/${currentbusiness?.id}/kyc/${findRecord?.id}`,
          {
            step: 2,
            accountNumber: values.accountNumber,
            // bankId: values.bankName,
            bankId: bankAccId,
            bankAccountName: accName,
          }
        );
        toast.success(res.message);
        await dispatch(
          setKycBoardingRec(
            kycBoardingRec instanceof Object && {
              ...kycBoardingRec,
              accNum,
              bankName,
              accName,
              bankAccId,
              businessId: currentbusiness.id,
            }
          )
        );
        continueEvent();
      } else {
        // alert("new");
        await api.post(`/business/${currentbusiness?.id}/kyc`, {
          type: "GATEWAY",
          accountNumber: values.accountNumber,
          bankId: bankAccId,
          bankAccountName: accName,
        });
        let busList = await api.get("/client/business");
        if (busList?.payload instanceof Array) {
          let findBusiness = busList?.payload.find(
            (el: any) => currentbusiness?.id === el?.id
          );
          if (findBusiness) {
            findRecord =
              findBusiness?.BusinessKyc instanceof Array &&
              findBusiness?.BusinessKyc.find(
                (el: any) => el?.type.toLowerCase() === "gateway"
              );
            let businessKycId = findRecord?.id;

            res = await api.patch(
              `/business/${currentbusiness?.id}/kyc/${businessKycId}`,
              {
                step: 2,
                accountNumber: values.accountNumber,
                bankId: bankAccId,
                bankAccountName: accName,
              }
            );
            toast.success(res.message);
            await dispatch(
              setKycBoardingRec(
                kycBoardingRec instanceof Object && {
                  ...kycBoardingRec,
                  accNum,
                  bankName,
                  accName,
                  bankAccId,
                  businessId: currentbusiness.id,
                }
              )
            );
            await dispatch(setBusinessLists(busList, true));
            await dispatch(setCurrentBusiness(findBusiness));

            continueEvent();
            setIsLoading(false);
            return;
          }
        }

        consoleLog("Postresmessage");
        toast.error(
          "Unable to to upload documentation, Kyc documentation not properly done."
        );
      }
    } catch (error) {
      consoleLog("err ", error);
      // @ts-ignore
      toast.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (e: any) => {
    const filtedBank: any = banks.find((bank: any) => bank.value === e);
    consoleLog("selected Bank:", filtedBank)
    if (filtedBank) {
      setBankName(filtedBank.label);
      setBankCode(filtedBank.code);
      setBankAccId(filtedBank.value);
    }
    // consoleLog("selected Bank:", e)
    // function search(e: any, arr: any) {
    //   for (var i = 0; i < arr.length; i++) {
    //     if (arr[i].value === e) {

    //       return arr[i];
    //     }
    //   }
    // }

    // search(e, banks);
  };

  const continueEvent = () => {
    history.push("/kyc/gateway-upload-documents");
  };

  useEffect(() => {
    getBankList();
  }, []);

  useEffect(() => {
    if (bankCode !== "" && accNum !== "" && accNum.length >= 10) {
      getDetails();
    }
  }, [accNum, bankCode]);

  const goBack = () => {
    history.push("/kyc/gateway-business-details");
  };

  const close = () => {
    history.push("/overview");
  };

  let urlAddress = history?.location?.pathname;

  return (
    <ParentCont>
      <LeftPanel>
        <FormCont>
          <TitleCont>
            {urlAddress !== kycStart && (
              <Back onClick={goBack}>
                <Icon type={"chevron-left"} size={20} />
              </Back>
            )}

            <FormTitle>Add Account Details</FormTitle>
          </TitleCont>

          <FormSubTitle>
            With your corporate account number, you are guaranteed of your
            settlement.
          </FormSubTitle>
          {/* @ts-ignore */}

          <Form
            enableReinitialize
            initialValues={initialValues}
            validations={{
              accountNumber: [Form.is.required()],
              bankAccId: [Form.is.required()],
              accountName: [Form.is.required()],
              bankName: [Form.is.required()]
            }}
            validate={() => {}}
            onSubmit={onSubmit}
            validateOnBlur={false}
          >
            <FormElement>
              {loadingBanks && (
                <h4 style={{ marginTop: 10, marginBottom: 0 }}>
                  Bank list loading...
                </h4>
              )}

              <Form.Field.Select
                name="bankName"
                placeholder="Bank Name"
                options={banks}
                onChange={handleSelect}
              />

              <Form.Field.Input
                value={accNum}
                name="accountNumber"
                type="text"
                placeholder="Account Number"
                onChange={(val: string) => setAccNum(val)}
                // tip="Kindly enter your user email address."
              />

              <Form.Field.Input
                name="accountName"
                type="text"
                placeholder="Bank Account Name"
                value={loadingAcc ? "verifying bank account..." : accName}
                disabled={!accNameFetch}
                onChange={(val: string) => setAccName(val)}
                // tip="Kindly enter your user password."
              />

              {/* {
                !loadingAcc
                && nameMatch
                 && (
                  <Indicator bg={nameMatch}>
                    <span>Name match rate: {nameMatch}% </span>
                    <span>Status: {nameMatch < 0.8 ? "FAIL" : "PASS"}</span>
                  </Indicator>
                )
              } */}

              <Selectors>
                <ActionButton
                  type="submit"
                  variant="primary"
                  isWorking={isLoading}
                  disabled={accNum === "" || bankCode === "" || accName === ""}
                >
                  {checkExistingKycBoarding(
                    `accNum,bankName,accName`,
                    kycBoardingRec
                  )
                    ? "Update"
                    : "Save"}{" "}
                  and Continue
                </ActionButton>
              </Selectors>
            </FormElement>
          </Form>
        </FormCont>
      </LeftPanel>

      <MiddlePanel>
        <PageIndicator pageArr={GatewayPages} page={1} />
      </MiddlePanel>

      <RightPanel>
        <RightPanelAuxCont>
          <CloseBtn onClick={close}>
            <Icon type={"close"} size={20} />
          </CloseBtn>
        </RightPanelAuxCont>

        <RightPanelAuxCon2>
          <DescriptionBox>
            <DescriptionTitle>Gateway</DescriptionTitle>

            <DescriptionText>
              Enjoy, quick, and secure way to send and receive money.
            </DescriptionText>

            <DescriptionBtn
              onClick={() =>
                window.open(
                  "https://medium.com/@fountainpay",
                  "noopener,noreferrer"
                )
              }
            >
              Learn more
            </DescriptionBtn>
          </DescriptionBox>
        </RightPanelAuxCon2>
      </RightPanel>
    </ParentCont>
  );
}

export default AddAccount;
